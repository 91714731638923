const TalTechSVG = () => {
	return (
		<>
			<div className="branding pt-3">
				<svg className="svg-displacement"
					data-name="FixedTransparentLogo v4"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 223.03 126.084">
					<path
						d="M 0 16.05 L 15.98 16.05 L 15.98 60.83 L 34.02 60.83 L 34.02 16.05 L 49.91 16.05 L 49.91 0 L 0 0 L 0 16.05 Z"
						fill="white"></path>
					<path
						d="M 79.15 39.08 L 67.19 39.08 L 73.17 19.4 L 79.15 39.08 Z M 61.54 0 L 39.58 60.84 L 60.02 60.84 L 62.17 53.72 L 83.6 53.72 L 85.75 60.84 L 106.2 60.84 L 84.21 0 L 61.54 0 Z"
						fill="white"></path>
					<path
						d="M 127.48 44.78 L 150.06 44.78 L 150.05 60.83 L 109.43 60.83 L 109.43 0 L 127.47 0 L 127.47 44.78 L 127.48 44.78 Z"
						fill="white"></path>
					<path
						d="M 16.03 126.08 L 16.005 103.39 L 15.98 80.7 L 0 80.7 L 0 64.64 L 24.955 64.64 L 49.91 64.64 L 49.91 80.7 L 34.02 80.7 L 34.025 103.39 L 34.03 126.08 L 16.03 126.08 Z"
						fill="white"></path>
					<path
						d="M 54.03 126.08 L 54.045 95.36 L 54.06 64.64 L 99.24 64.64 L 99.24 80.7 L 72.1 80.7 L 72.1 86.71 L 98.62 86.71 L 98.62 102.77 L 72.1 102.77 L 72.1 109.43 L 99.24 109.43 L 99.24 126.08 L 54.03 126.08 Z"
						fill="white"></path>
					<path
						d="M 123.03 126.08 C 116.89 123.94 111.703 119.782 108.05 114.368 C 104.398 108.953 102.28 102.28 102.28 95.11 C 101.985 77.875 114.12 67.135 127.739 64.351 C 141.358 61.568 156.46 66.74 162.1 81.33 L 146.56 88.71 C 143.72 81.06 137.125 78.778 131.29 80.349 C 125.455 81.92 120.38 87.345 120.58 95.11 C 120.38 102.875 125.455 108.295 131.29 109.861 C 137.125 111.427 143.72 109.14 146.56 101.49 L 162.1 108.78 C 160.845 111.715 158.425 115.225 155.27 118.407 C 152.115 121.59 148.225 124.445 144.03 126.07 L 123.03 126.08 Z"
						fill="white"></path>
					<path
						d="M 183.65 103.21 L 205 103.21 L 204.91 126.081 L 223.03 126.08 L 223.03 64.63 L 204.63 64.63 L 204.63 86.15 L 183.29 86.15 L 183.29 64.63 L 165.25 64.63 L 165.03 126.084 C 188.041 126.082 183.65 126.082 183.65 126.082 L 183.65 103.21 Z"
						fill="white"></path>
				</svg>
			</div>
		</>
	);
};

export default TalTechSVG;
